<template>
    <div>
        <sn-table-group :tableGroupAttributes="tableGroupAttributes" @handleChange="handleChange"></sn-table-group>
    </div>
</template>
<script>

import { commonData } from '../../../sn-table-group-common';
import { selfData } from './model/sn-table-group';
import commonMethodMixin from '../../../commonMethodMixin';
import { mapActions } from 'vuex';
export default {
    name: 'equipmentdata_microenvironment_grid',
    mixins: [commonMethodMixin],
    data() {
        return {
            commonData,
            selfData,
            infoPage: 'equipmentdata_microenvironment_form',
            // downUrl: DOWN_URL + '/api/FileDownLoad/OnPostExport?funId=0002',
            // 筛选项
            keyword: '', // 关键字
            itemID: '8020502',
        };
    },
    mounted() {
    },
    methods: {
        ...mapActions([
            'DeleteData',
            'GetNaturalEnvironmentList'
        ]),
        async getTableData() {
            // 处理筛选，支持多项同时筛选
            let searchList = [];
            if (this.dateSearchData && this.dateSearchData.length > 0) {
                this.dateSearchData.forEach((item) => {
                    if (
                        (typeof item.value === "string" && item.value !== "") ||
                        (item.value !==null && typeof item.value === "object" &&
                            Object.keys(item.value).length > 0)
                    ) {
                        if (item.type === "startTime") {
                            let jsonObj = {
                                FieldName: 'JCSJ',
                                FieldValue: item.value,
                                OperatorChar: ">"
                            };
                            searchList.push(jsonObj);
                        }else if(item.type === "endTime"){
                            let jsonObj = {
                                FieldName: 'JCSJ',
                                FieldValue: item.value,
                                OperatorChar: "<"
                            };
                            searchList.push(jsonObj);
                        }
                    }
                });
            }

            let jsonStr = JSON.stringify(searchList);
            this.tableGroupAttributes.loading = true;
            let res = await this.GetNaturalEnvironmentList({
                _cols: [],	//字段集
                _keyword: this.keyword,	//查询关键字
                _serarchCols: JSON.stringify(["SBMC","JCSJ","FX","FS","YL","WD","SD","QY","TYFS"]),	//关键字字段集合
                _conditionList: jsonStr,	//过滤条件集合
                _dicOrderby: {"JCSJ":"desc"},	
                _pageSize: this.tableGroupAttributes.snPage.tiaoshu,	//每页最大记录数
                _pageIndex: this.tableGroupAttributes.snPage.currentPage,	//页索引
                _returnSum: true,	//是否返回记录总数
                _needVersion: false,	//是否分版本查询
                _searchFormal: false,    //T：查询最近的正式版本数据；F：查询最近的数据
                itemid: this.itemID, 	//业务编号
            })
            this.tableGroupAttributes.snTable.table.data = res.data || [];
            this.tableGroupAttributes.snPage.count = Number(res.sum) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 删除数据
        // eslint-disable-next-line no-unused-vars
        async delData(id, done, instance) {
            let res = await this.DeleteData({
                ID: id,
                itemid: this.itemID
            });
            this.delOver(res, done);
        },
    },
};
</script>
<style lang="scss" scoped>

</style>