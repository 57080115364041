const selfData = {
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [{
                label: "设备",
                prop: "SBMC",
                minWidth: "180"
            }, {
                label: "时间",
                prop: "JCSJ",
                minWidth: "180"
            }, {
                label: "风向(度)",
                prop: "FX",
                minWidth: "120"
            }, {
                label: "风速(m/s)",
                prop: "FS",
                minWidth: "120"
            }, {
                label: "雨量(mm)",
                prop: "YL",
                minWidth: "120"
            }, {
                label: "气温(℃)",
                prop: "WD",
                minWidth: "120"
            }, {
                label: "相对湿度(%)",
                prop: "SD",
                minWidth: "120"
            }, {
                label: "气压(Pa)",
                prop: "QY",
                minWidth: "120"
            }, {
                label: "太阳辐射(mwh/cm²)",
                prop: "TYFS",
                minWidth: "180"
            }],
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page'
    },
    snSearch: {
        dateData: [{
            placeholder: "请选择开始时间",
            value: 'startTime',
            operateType: 'search',
            isShow: true
        }, {
            placeholder: "请选择结束时间",
            value: 'endTime',
            operateType: 'search',
            isShow: true
        }]
    },
    snButton: {
        buttonData: [{
        //     isShow: true,
        //     btnType: 'button',
        //     operateType: 'buttonNew',
        //     name: '新建',
        //     round: true,
        //     backColor: '#28ccd9',
        // }, {
            btnType: 'button',
            operateType: 'buttonExport',
            name: '导出',
            round: true,
            backColor: '#ffcc66',
            color: '#fff'
        }]
    }
};
export { selfData };